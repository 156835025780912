import {mapActions, mapGetters, mapMutations} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'
import { KinesisContainer, KinesisElement} from "vue-kinesis"
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: "process",
  components: {
    VueSlickCarousel,
    KinesisContainer,
    KinesisElement
  },
  data() {
    return {
      width: window.innerWidth,
      productsSlider: {
        "infinite": true,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "initialSlide": 0,
        arrows: false,
      },
      processSlider: {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "centerMode": true,
        "centerPadding": "120px",
        "focusOnSelect": true,
        "arrows": false,
        "responsive": [
          {
            "breakpoint": 550,
            "settings": {
              "centerPadding": "100px",
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "centerPadding": "80px",
            }
          },
          {
            "breakpoint": 440,
            "settings": {
              "centerPadding": "65px",
            }
          },
          {
            "breakpoint": 400,
            "settings": {
              "centerPadding": "50px",
            }
          },
          {
            "breakpoint": 375,
            "settings": {
              "centerPadding": "45px",
            }
          },
        ]
      }
    }
  },
  created() {
    this.getProcess()
    this.getProducts()
  },
  computed: {
    ...mapGetters({
      process: 'setting/productProcess',
      products:'setting/process'
    })
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({
      getProcess: 'setting/GET_PRODUCT_PROCESS',
      getProducts: 'setting/GET_PROCESS'
    }),
    carouselNav($event, direction) {
      const carousel = this.$refs['productionCarousel'];
      carousel.advancePage(direction);
    },
  }
}
